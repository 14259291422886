:root {
  --black: rgb(0, 0, 0);
  --white: rgb(255, 255, 255);
  --grey: rgb(184, 184, 184);
}

.searchPanel {
  margin: 0 auto;
  width: 820px;
  min-height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchPanel h1 {
  color: var(--black);
}
.searchForm {
  margin: 0 auto;
  display: flex;
  position: relative;
}
.autocomplete {
  position:absolute;
  left: 0;
  top: 34px;
  width: 80.2%;
  background-color: var(--white);
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 12;
  max-height: 240px;
  overflow: auto;
  height: auto;
  border: 1px solid var(--black);
}
.autocomplete__item {
  padding: 10px;
}
.autocomplete__item:hover {
  background-color: var(--grey);
  cursor: pointer;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s all;
}
.searchForm__input {
  width: 430px;
  height: 35px;
  border-color: var(--black);
  border-width: 2px;
  padding-left: 10px;
}
.searchForm button {
  margin: 0px auto 0 5px;
  width: 100px;
  height: 35px;
  border: 0;
  border-radius: 5px;
  background:  var(--black);
  color: var(--white);
  font-size: 16px;
}

.searchForm button:hover {
  background-color:  var(--black);
  transform: scale(1.05);
}

.movie__item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.movie__list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  justify-content: center;
}

.movie__image {
  width: 140px;
}

.movie-item > p  {
  width: 100px;
}

.ant-card-body {
  padding: 3px;
}

.ant-card-meta-title {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.78);
  font-weight: 500;
  font-size: 16px;
  text-overflow: ellipsis;
  height: 50px;
  white-space: normal
}

.ant-card-bordered {
 border: 2px solid  var(--black);
}

.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: -7px;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  margin: 0px 13px;
}
.movie__favorites {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  transform: translate(-7px, 1px);
}

.movie__not-found {
  font-size: 50px;
}